@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.ttf') format('Medium');
  font-weight: 400;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:
    'WixMadeforDisplay',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;
  touch-action: none;
  -ms-touch-action: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* -moz-user-zoom: fixed;
  -webkit-user-zoom: fixed;
  -ms-user-zoom: fixed; */
}

a,
button,
input,
textarea {
  -webkit-tap-highlight-color: transparent;
  font-family: 'WixMadeforDisplay', 'HelveticaNeue', 'Trispace', Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  font-family: 'WixMadeforDisplay', 'HelveticaNeue', 'Trispace', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
